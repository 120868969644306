.error_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 33px;
  margin-bottom: 40px;
  gap: 20px;
}

.error_heading {
  color: #000;
  font-size: 31px;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.error_subheading {
  color: #5f5f5f;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 154.523%;
  max-width: 550px;
}

.error_btn_area {
  display: flex;
  gap: 20px;
}
